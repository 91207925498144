'use client';

import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Title } from 'components/page/advertisement/listing/components/listing/partials/zeroResults/partials/title/Title';
import { SubTitle } from 'components/page/advertisement/listing/components/listing/partials/zeroResults/partials/subTitle/SubTitle';
import ZeroResultsIcon from 'components/page/advertisement/listing/assets/icons/ZerorResultsIcon.svg';

export const ZeroResults: FC = () => {
    return (
        <Box
            mt={4}
            mb={6}
            width='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
        >
            <Stack
                rowGap={2}
                alignItems='center'
                justifyContent='center'
                width={(theme: Theme) => theme.spacing(44)}
                textAlign='center'
            >
                <Svg
                    icon
                    component={ZeroResultsIcon}
                    width={256}
                    height={120}
                />
                <Title />
                <SubTitle />
            </Stack>
        </Box>
    );
};
