import React, { FC, useMemo } from 'react';
import { Link } from 'components/carousel/cssCarousel/components/partials/link/Link';
import { CssCarousel } from 'components/carousel/cssCarousel/components/CssCarousel';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { useDevProjects } from 'components/page/advertisement/listing/hooks/promoDevProject/useDevProjects';
import { IDevProject as IPromoDevProject } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/IDevProject';
import { PromoDevProjectCardSSR } from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/PromoDevProjectCardSSR';

import { mapIAdvertisementsLisDevProjectToDsIDevProject } from 'components/page/advertisement/listing/helpers/mapper/devProject/partials/mapIAdvertisementsLisDevProjectToDsIDevProject';

interface IPromoDevProjectCarousel {
    devProjectsInitial: IDevProject[];
}
export const PromoDevProjectCarousel: FC<IPromoDevProjectCarousel> = ({
    devProjectsInitial,
}) => {
    const { devProjects, fetchMore } = useDevProjects(devProjectsInitial);

    const items = useMemo(() => {
        return [
            ...devProjects.map((devProject: IDevProject) => {
                const promoDevProject: IPromoDevProject =
                    mapIAdvertisementsLisDevProjectToDsIDevProject(devProject);

                return (
                    <Link
                        tabIndex={-1}
                        target='_blank'
                        key={promoDevProject.projectId}
                        href={devProject.devProjectDetailUrl}
                    >
                        <PromoDevProjectCardSSR devProject={promoDevProject} />
                    </Link>
                );
            }),
        ].filter((project) => project);
    }, [devProjects]);

    return (
        <CssCarousel
            ssr
            arrows
            showDots
            draggable
            swipeable
            removeArrowOnDeviceType={['mobile', 'tablet']}
            items={items}
            slidesToSlide={1}
            onFetchNext={fetchMore}
        />
    );
};
