import { FC } from 'react';
import { Box } from '@mui/material';
import { IDevProject } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/IDevProject';
import { Photo } from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/photo/Photo';
import {
    Content
} from 'modules/theme/components/listing/cards/promoDevProjectCard/components/card/partials/content/Content';

interface IPromoDevProjectCard {
    devProject: IDevProject;
}

export const PromoDevProjectCardSSR: FC<IPromoDevProjectCard> = ({
    devProject,
}) => {
    const {
        imageUrl,
        location,
        projectName,
        advertisementsCategories,
        totalAdvertisementsCount,
        price: { price, unitPrice },
        packages: { isTop, isPremium },
    } = devProject;

    return <Box borderRadius={2} overflow='hidden' height={254} component='span' display='block' width='100%'>
        <Box position='relative' height='100%' component='span' display='block'>
            <Content
                isTop={isTop}
                price={price}
                location={location}
                isPremium={isPremium}
                unitPrice={unitPrice}
                projectName={projectName}
                advertisementsCategories={advertisementsCategories}
                totalAdvertisementsCount={totalAdvertisementsCount}
            />

            <Photo photoUrl={imageUrl}/>
        </Box>
    </Box>;
};