import { FC } from 'react';
import { Box, Palette, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import QuoteLeftIcon from 'modules/theme/components/icons/basic/QuoteLeft.svg';
import QuoteRightIcon from 'modules/theme/components/icons/basic/QuoteRight.svg';

interface IDescription {
    text: string;
}

export const Description: FC<IDescription> = ({ text }) => {
    return (
        <Box>
            <Box
                gap={1.5}
                display='flex'
                justifyContent='center'
                sx={(theme: Theme) => ({
                    color: theme.palette.labelTertiary.main,
                })}
            >
                <Svg icon width={24} component={QuoteLeftIcon} />

                <Text
                    variant='label1'
                    mobileVariant='label2'
                    color={(palette: Palette) => palette.labelSecondary}
                >
                    {text}
                </Text>

                <Box alignSelf='flex-end'>
                    <Svg icon width={24} component={QuoteRightIcon} />
                </Box>
            </Box>
        </Box>
    );
};
