import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const SubTitle: FC = () => {
    return (
        <Text variant='body2'>
            Uložte si toto hľadanie a upozorníme vás, keď tu pribudne nová
            ponuka.
        </Text>
    );
};
