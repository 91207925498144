'use client';

import { FC, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import isHeaderHidden from 'modules/layout/isHeaderHidden';
import {
    AdvertisementsSearchFilter
} from 'components/advertisementsFilter/components/AdvertisementsSearchFilter';
import { NotificationCenter } from 'components/notificationCenter/NotificationCenter';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';

interface ITopListingContent {
    filterDataFromUrl: FilterDataFromUrlType;
    initialSearchFilterData: IInitialSearchFilterData;
    pagination: IAdvertisementListingFilterPagination;
}

export const TopListingContent: FC<ITopListingContent> = ({
    pagination,
    filterDataFromUrl,
    initialSearchFilterData,
}) => {
    const pathname = usePathname();
    const { breakpoints } = useTheme();
    const isMdDown = useMediaQuery(breakpoints.down('md'));

    const showNotifications = useMemo(() => {
        return isHeaderHidden(pathname) && isMdDown;
    }, [pathname, isMdDown]);

    return <Box
        top={{ xs: 0 }}
        zIndex={{ xs: 10 }}
        position={{ xs: 'sticky', md: 'relative' }}
        sx={(theme) => ({
            borderBottom: { md: `1px solid ${theme.palette.dividerTransparent}` },
            boxShadow: { xs: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)', md: 'none' },
            backgroundColor: theme.palette.backgroundPrimary.main,
        })}
    >
        {showNotifications && <NotificationCenter />}

        <Container>
            <Box
                pb={2}
                pt={2}
                display='flex'
                justifyContent='center'
            >
                <AdvertisementsSearchFilter
                    pagination={pagination}
                    filterDataFromUrl={filterDataFromUrl}
                    initialSearchFilterData={initialSearchFilterData}
                />
            </Box>
        </Container>
    </Box>;
};