import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import NextIcon from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg?url';
import PrevIcon from 'modules/theme/components/icons/basic/KeyboardArrowLeft.svg?url';

interface IArrowButton {
    isPrev?: boolean;
}

export const ArrowButton: FC<IArrowButton> = ({
    isPrev,
    ...rest
}) => {
    const { palette } = useTheme();

    return (
        <Box
            {...rest}
            sx={{
                top: '49%',
                width: 56,
                height: 56,
                minWidth: 56,
                minHeight: 56,
                cursor: 'pointer',
                position: 'absolute',
                borderRadius: '100%',
                backgroundPosition: 'center',
                transform: 'translate(0, 0)',
                backgroundRepeat: 'no-repeat',
                left: isPrev && '-27px!important',
                right: !isPrev && '-27px!important',
                backgroundColor: palette.backgroundPrimary.main,
                boxShadow: '0px 16px 32px rgba(32, 20, 20, 0.08)',
                border: `1px solid ${palette.labelQuarternary.main}`,
                backgroundImage: `url("${isPrev ? PrevIcon.src : NextIcon.src}")`,
            }}
        />
    );
};
