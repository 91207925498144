'use client';

import { FC } from 'react';
import {
    useAdvertisementsListingStore
} from 'components/page/advertisement/listing/store/advertisementsListingStore';
import { useCheckMultipleFavorite } from 'components/favorite/hooks/useCheckMultipleFavorite';

export const FavoriteAdvertisements: FC = () => {
    const advertisementIds = useAdvertisementsListingStore((store) => store.advertisementIds);

    useCheckMultipleFavorite(advertisementIds);

    return null;
};