import axios, { AxiosResponse } from 'axios';
import { advertisementFavoriteApi } from 'modules/api/client';
import { EFavoriteErrorStatusCode } from 'components/favorite/enums/error/messages/EFavoriteErrorStatusCode';
import { isValueInEnum } from 'modules/enums/checker/isValueInEnum';

export const addToFavorite = async (
    advertisementId: string
): Promise<AxiosResponse<void>> => {

    try {
        const response =
            await advertisementFavoriteApi.createAdvertisementFavourite(
                advertisementId
            );


        if (response.status !== 201) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (isValueInEnum(error.response?.status.toString(), EFavoriteErrorStatusCode)) {
                throw new Error(EFavoriteErrorStatusCode.UNPROCESSABLE_ENTITY_EXCEPTION);
            }
        }


        throw new Error(`Failed to add to favorite. Error: ${error.message}`);
    }
};
