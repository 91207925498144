'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Content } from 'components/notificationCenter/partials/content/Content';
import { INotification } from 'modules/stores/notificationCenter/useNotificationCenterStore';

interface Props {
    notification: INotification;
    onClose: () => void;
}

export const ModalContent: FC<Props> = ({ notification, onClose }) => {
    return (
        <Box position='relative'>
            <Box
                onClick={onClose}
                position='absolute'
                top={0}
                left={0}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Svg icon component={CloseIcon} width={24} />
            </Box>
            <Box textAlign='center'>
                <Svg component={notification.icon} width={64} />
                <Content html={notification.text} />
            </Box>
        </Box>
    );
};
