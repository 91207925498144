'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { Breadcrumb } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/common/breadcrumb/Breadcrumb';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
interface IBreadcrumbsBox {
    breadcrumbs: IBreadcrumb[];
}

export const BreadcrumbsBox: FC<IBreadcrumbsBox> = ({ breadcrumbs }) => {
    return (
        <>
            <Box
                width='100%'
                display='flex'
                flexWrap='wrap'
                justifyContent='center'
                alignItems='center'
                gap={1.5}
            >
                {breadcrumbs.map((breadcrumb, index) => (
                    <Breadcrumb key={index} breadcrumb={breadcrumb} />
                ))}
            </Box>
        </>
    );
};
