import { FC } from 'react';
import { Box } from '@mui/material';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const NavigationDot: FC = (props: Record<string, any>) => {
    const isHiddenNext = props.index >= props.carouselState.currentSlide + 4;
    const isHiddenPrev = props.index <= props.carouselState.currentSlide - 4;
    const isSmall =
        props.index === props.carouselState.currentSlide + 3 ||
        props.index === props.carouselState.currentSlide - 3;

    const dotWidth = props.active ? 16 : isSmall ? 5 : 8;

    if (isHiddenNext || isHiddenPrev) return;

    return (
        <Box
            component='li'
            sx={(theme) => ({
                marginX: '4px',
                width: dotWidth,
                borderRadius: '999px',
                height: isSmall ? 5 : 8,
                opacity: '100%!important',
                transition: 'all 0.2s ease',
                backgroundColor: props.active
                    ? `${theme.palette.labelPrimary.main}!important`
                    : `${theme.palette.backgroundTertiary.main}!important`,
            })}
        />
    );
};
