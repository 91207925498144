import { ReactNode, useCallback } from 'react';
import useLoading from 'modules/stores/loading/useLoading';
import { FavoriteActionType } from 'components/favorite/types/FavoriteActionType';
import { deleteFromFavorite } from 'components/favorite/api/deleteFromFavorite';
import { EFavoriteActions } from 'components/favorite/enums/EFavoriteActions';
import { addToFavorite } from 'components/favorite/api/addToFavorite';
import { useLoginModal } from 'components/loginModal/hooks/useLoginModal';
import useNotification from 'modules/notification/useNotification';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import { useFavoriteStore } from 'components/favorite/store/favoriteStore';
import { isValueInEnum } from 'modules/enums/checker/isValueInEnum';
import {
    FavoriteErrorStatusCodeToMessagesRecord
} from 'components/favorite/constants/records/error/FavoriteErrorStatusCodeToMessagesRecord';
import { EFavoriteErrorStatusCode } from 'components/favorite/enums/error/messages/EFavoriteErrorStatusCode';

interface IReturn {
    isLoading: boolean;
    loginModal: ReactNode;
    onAddOrDeleteFromFavorite: (
        action: FavoriteActionType,
        advertisementId: string,
    ) => void;
}

export const useFavoriteListing = (): IReturn => {
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const { loginModal, isLoggedIn, showLoginDialog } = useLoginModal();
    const {
        success: showSuccessNotification,
        info: showInfoNotification,
        error: showErrorNotification,
    } = useNotification();
    const setLoginPosition = useLoginStore((store) => store.setLoginPosition);
    const favoriteIds = useFavoriteStore((store) => store.favoriteIds);
    const setFavoriteIds = useFavoriteStore((store) => store.setFavoriteIds);

    const {
        set: setStorageAdvId,
    } = useLocalStorageItem('add-to-favorites');

    const onAddOrDeleteFromFavorite = useCallback(
        async (action: FavoriteActionType, advertisementId: string): Promise<void> => {
            if (!isLoggedIn) {
                setStorageAdvId(advertisementId);
                setLoginPosition(ELoginPosition.FAVORITE);
                return showLoginDialog();
            }

            const apiAction =
                action === EFavoriteActions.ADD_TO_FAVORITE
                    ? addToFavorite
                    : deleteFromFavorite;

            try {
                startLoading();

                await apiAction(advertisementId);

                if (action === EFavoriteActions.ADD_TO_FAVORITE) {
                    setFavoriteIds([
                        ...favoriteIds,
                        advertisementId,
                    ]);
                    showSuccessNotification('Inzerát pridaný medzi obľúbené');
                } else {
                    setFavoriteIds(favoriteIds.filter((id) => id !== advertisementId));
                    showInfoNotification('Inzerát odobraný z obľúbených');
                }

                stopLoading();
            } catch (error) {
                if (isValueInEnum(error.message, EFavoriteErrorStatusCode)) {
                    showErrorNotification(
                        FavoriteErrorStatusCodeToMessagesRecord[
                            error.message as EFavoriteErrorStatusCode
                        ]
                    );
                }
                console.warn(error.message);
            } finally {
                stopLoading();
            }
        },
        [
            isLoggedIn,
            favoriteIds,
            setFavoriteIds,
            setStorageAdvId,
            setLoginPosition,
            showLoginDialog,
            startLoading,
            stopLoading,
            showSuccessNotification,
            showInfoNotification,
            showErrorNotification,
        ]
    );

    return {
        isLoading,
        loginModal,
        onAddOrDeleteFromFavorite,
    };
};
