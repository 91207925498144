import { FC, useCallback } from 'react';
import { Box } from '@mui/material';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavigationDot } from 'components/carousel/cssCarousel/components/partials/navigationDot/NavigationDot';
import { ArrowButton } from 'components/carousel/cssCarousel/components/partials/arrowButton/ArrowButton';

interface ICssCarousel {
    ssr?: boolean;
    arrows?: boolean;
    showDots?: boolean;
    rewind?: boolean;
    infinite?: boolean;
    draggable?: boolean;
    swipeable?: boolean;
    autoplay?: boolean;
    autoplaySpeed?: number;
    items: JSX.Element[];
    itemMinWidth?: number;
    slidesToSlide: number;
    onFetchNext?: () => void;
    tabletItemsCount?: number;
    mobileItemsCount?: number;
    desktopItemsCount?: number;
    spaceBetween?: number;
    removeArrowOnDeviceType?: string[];
    desktopBreakpointMax?: number;
    tabletBreakpointMax?: number;
    mobileBreakpointMax?: number;
    desktopBreakpointMin?: number;
    tabletBreakpointMin?: number;
    mobileBreakpointMin?: number;
}

const PREVIEW_SLIDE = 1;

export const CssCarousel: FC<ICssCarousel> = ({
    ssr,
    items,
    arrows,
    showDots,
    infinite,
    autoplay,
    autoplaySpeed,
    draggable,
    swipeable,
    onFetchNext,
    slidesToSlide,
    mobileItemsCount,
    tabletItemsCount,
    desktopItemsCount,
    spaceBetween = 5,
    desktopBreakpointMax = 3000,
    desktopBreakpointMin = 1024,
    tabletBreakpointMax = 1024,
    tabletBreakpointMin = 464,
    mobileBreakpointMax = 464,
    mobileBreakpointMin = 0,
    removeArrowOnDeviceType = ['mobile'],
}) => {
    const onLoadNext = useCallback(
        (currentSlide: number): void => {
            if (currentSlide === items.length / 2 - PREVIEW_SLIDE) {
                onFetchNext();
            }
        },
        [onFetchNext, items.length]
    );

    const desktopItems = desktopItemsCount || 4;
    const tabletItems = tabletItemsCount || 2;
    const mobileItems = mobileItemsCount || 1;

    const responsive: ResponsiveType = {
        desktop: {
            breakpoint: {
                max: desktopBreakpointMax,
                min: desktopBreakpointMin,
            },
            items: desktopItems,
        },
        tablet: {
            breakpoint: {
                max: tabletBreakpointMax,
                min: tabletBreakpointMin,
            },
            items: tabletItems,
        },
        mobile: {
            breakpoint: {
                max: mobileBreakpointMax,
                min: mobileBreakpointMin,
            },
            items: mobileItems,
        },
    };

    return (
        <Box
            sx={{
                '& > div': {
                    position: 'inherit!important',
                },
                '& .react-multi-carousel-dot-list': {
                    alignItems: 'center!important',
                    position: 'relative!important',
                    marginTop: '28px!important',
                },
            }}
        >
            <Carousel
                ssr={ssr}
                rewind={false}
                arrows={arrows}
                showDots={showDots}
                infinite={infinite}
                autoPlay={autoplay}
                autoPlaySpeed={autoplaySpeed}
                swipeable={swipeable}
                draggable={draggable}
                responsive={responsive}
                renderDotsOutside={true}
                rewindWithAnimation={false}
                customDot={<NavigationDot />}
                slidesToSlide={slidesToSlide}
                renderButtonGroupOutside={false}
                renderArrowsWhenDisabled={false}
                customRightArrow={<ArrowButton />}
                customLeftArrow={<ArrowButton isPrev />}
                removeArrowOnDeviceType={removeArrowOnDeviceType}
                afterChange={(prevSlide, { currentSlide }) => {
                    if (typeof onFetchNext !== 'undefined') {
                        onLoadNext(currentSlide);
                    }
                }}
            >
                {items.map((item, index) => {
                    return (
                        <Box key={index} sx={{ paddingX: `${spaceBetween}px` }} tabIndex={-1}>
                            {item}
                        </Box>
                    );
                })}
            </Carousel>
        </Box>
    );
};

