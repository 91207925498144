import { ECategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';
import { ECategoryToDsCategory } from 'components/page/advertisement/listing/constants/records/result/advertisementCard/category/ECategoryToDsCategory';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import { IPrice } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/price/IPrice';
import { IAdvertisement } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import { IPhotos } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/photos/IPhotos';
import { IAgency } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/agency/IAgency';
import { ISeller } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/seller/ISeller';
import { IAddress } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/address/IAddress';
import { IPackages } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/packages/IPackages';
import { ESubCategoryToDsSubCategory } from 'components/page/advertisement/listing/constants/records/result/advertisementCard/subcategory/ESubCategoryToDsSubCategory';
import { IAttachments } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/attachments/IAttachments';
import { IListingUrl } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/listingUrl/IListingUrl';
import { IDevProject } from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
import { IAdvertisementsListResultAdvertisement } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/IAdvertisementsListResultAdvertisement';
import { mapIAdvertisementsListResultAdvertisementPriceToDsIAdvertisementIPrice } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/price/mapIAdvertisementsListResultAdvertisementPriceToDsIAdvertisementIPrice';
import { mapIAdvertisementsListResultAdvertisementPhotoToDsIAdvertisementIPhotos } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/photos/mapIAdvertisementsListResultAdvertisementPhotoToDsIAdvertisementIPhotos';
import { mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIPackages } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/packages/mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIPackages';
import { mapIAdvertisementsListResultAdvertisementLocationToDsIAdvertisementIAddress } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/address/mapIAdvertisementsListResultAdvertisementLocationToDsIAdvertisementIAddress';
import { mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementIAgency } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/agency/mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementIAgency';
import { mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementISeller } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/seller/mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementISeller';
import { mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIAttachments } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/attachments/mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIAttachments';
import { mapIAdvertisementsListResultAdvertisementASearchResultLinkToDsIListingUrl } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/listingUrl/mapIAdvertisementsListResultAdvertisementASearchResultLinkToDsIListingUrl';
import { mapIAdvertisementsListResultAdvertisementDevProjectToDsIDevProject } from 'components/page/advertisement/listing/helpers/mapper/result/advertisement/partials/devProject/mapIAdvertisementsListResultAdvertisementDevProjectToDsIDevProject';
import { ESubCategory as EApiSubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const mapIAdvertisementsListResultAdvertisementToDsIAdvertisement = (
    apiAdvertisement: IAdvertisementsListResultAdvertisement,
    filterCategoriesForDevProject: (EMainCategory | EApiSubCategory)[],
    isFavorite?: boolean,
    priorityLoad?: boolean,
): IAdvertisement => {
    const {
        id: apiId,
        title: apiTitle,
        price: apiPrice,
        flags: apiFlags,
        photos: apiPhotos,
        sefName: apiSefName,
        location: apiLocation,
        devProject: apiDevProject,
        parameters: apiParameters,
        advertiser: apiAdvertiser,
        description: apiDescription,
        searchResultsLink: apiSearchResultsLink,
    } = apiAdvertisement;

    const photos: IPhotos =
        mapIAdvertisementsListResultAdvertisementPhotoToDsIAdvertisementIPhotos(
            apiPhotos,
            priorityLoad,
        );

    const address: IAddress =
        mapIAdvertisementsListResultAdvertisementLocationToDsIAdvertisementIAddress(
            apiLocation
        );

    const price: IPrice =
        mapIAdvertisementsListResultAdvertisementPriceToDsIAdvertisementIPrice(
            apiPrice
        );

    const packages: IPackages =
        mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIPackages(
            apiFlags
        );

    const attachments: IAttachments =
        mapIAdvertisementsListResultAdvertisementFlagsToDsIAdvertisementIAttachments(
            apiFlags
        );

    let agency: IAgency;

    if (!!apiAdvertiser?.parent) {
        agency =
            mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementIAgency(
                apiAdvertiser.parent
            );
    } else {
        agency = { nameOfAdvertiser: 'Priamo od majiteľa' };
    }

    const seller: ISeller =
        mapIAdvertisementsListResultAdvertisementAdvertiserToDsIAdvertisementISeller(
            apiAdvertiser
        );

    const listingUrl: IListingUrl =
        mapIAdvertisementsListResultAdvertisementASearchResultLinkToDsIListingUrl(
            apiSearchResultsLink
        );

    const isDevProject = !!apiDevProject;

    let devProject: IDevProject;
    if (isDevProject) {
        devProject =
            mapIAdvertisementsListResultAdvertisementDevProjectToDsIDevProject(
                apiDevProject,
                filterCategoriesForDevProject,
            );
    }

    const category: ECategory =
        ECategoryToDsCategory[apiParameters.category.mainValue];

    const subcategory: ESubcategory =
        ESubCategoryToDsSubCategory[apiParameters.category.subValue];

    const area = apiParameters?.area || undefined;
    const transaction = apiParameters.transaction;
    const realEstateState = apiParameters?.realEstateState || undefined;

    isFavorite = !!isFavorite;

    return {
        area,
        price,
        seller,
        photos,
        agency,
        address,
        packages,
        category,
        id: apiId,
        devProject,
        listingUrl,
        isFavorite,
        subcategory,
        attachments,
        transaction,
        isDevProject,
        realEstateState,
        title: apiTitle,
        slug: apiSefName,
        description: apiDescription,
        externalId: '', //nieje potrebne
    };
};
