import { FC } from 'react';
import Link from 'components/link/Link';
import { Text } from 'modules/theme/components/text/Text';
import { Palette } from '@mui/material';

interface IProps {
    title: string;
    url: string;
}

export const BreadcrumbUrl: FC<IProps> = ({ url, title }) => {
    return (
        <Link href={url}>
            <Text
                variant='breadcrumb'
                color={(palette: Palette) => palette.labelSecondary}
                underLine
            >
                {title}
            </Text>
        </Link>
    );
};
