import { create } from 'zustand';
import { FC, SVGProps } from 'react';
import { NotificationResponseDto } from 'modules/api/generated';

export interface INotification extends NotificationResponseDto {
    icon: FC<SVGProps<SVGSVGElement>>;
}

type NotificationCenterStore = {
    notification: INotification;
    setNotification: (notification: INotification) => void;

};

export const useNotificationCenterStore = create<NotificationCenterStore>((set) => ({
    notification: null,
    setNotification: (notification) => set(() => ({
        notification,
    })),
}));