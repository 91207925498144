'use client';

import { FC, useEffect } from 'react';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { useAdvertisementsListingStore } from 'components/page/advertisement/listing/store/advertisementsListingStore';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { IAdvertisementGtmListingItem } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';

interface IStoreSetter {
    breadcrumbs: IBreadcrumb[];
    advertisementsList: IAdvertisementsList;
    advertisementGtmItems: IAdvertisementGtmListingItem[];
}

export const StoreSetter: FC<IStoreSetter> = ({
    advertisementsList,
}) => {
    const setAdvertisementsIds = useAdvertisementsListingStore((store) => store.setAdvertisementsIds);

    useEffect(() => {
        setAdvertisementsIds(advertisementsList.results);
    }, [setAdvertisementsIds, advertisementsList.results]);

    return null;
};