import { FC } from 'react';
import { Box, Divider } from '@mui/material';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { PromoDevProjects } from 'components/page/advertisement/listing/components/promoDevProjects/PromoDevProjects';

interface IExclusiveDevProjects {
    devProjectsInitial: IDevProject[];
}

export const ExclusiveDevProjects: FC<IExclusiveDevProjects> = ({
    devProjectsInitial,
}) => {
    return (
        <>
            <Box mb={4}>
                <Divider sx={(theme) => ({
                    borderColor: theme.palette.dividerTransparent
                })}/>
            </Box>
            <Box mb={8}>
                <PromoDevProjects
                    devProjectsInitial={devProjectsInitial}
                />
            </Box>
            <Box mb={4}>
                <Divider sx={(theme) => ({
                    borderColor: theme.palette.dividerTransparent
                })}/>
            </Box>
        </>
    );
};
