'use client';

import React, { FC } from 'react';
import { PromoModule } from 'components/page/advertisement/listing/components/common/promoModule/PromoModule';
import SaveSearchIcon from 'components/page/advertisement/listing/assets/icons/SaveSearchIcon.svg';
import useSearchAgent from 'components/searchAgent/hooks/useSearchAgent';

export const SearchAgentModule: FC = () => {
    const { modal, show } = useSearchAgent();

    return (
        <>
            <PromoModule
                btnText='Uložiť hľadanie'
                icon={SaveSearchIcon}
                title='Uložte si toto hľadanie'
                description='Upozorníme vás na všetky nové podobné nehnuteľnosti'
                onClick={show}
            />
            {modal}
        </>
    );
};
