'use client';

import { FC, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Box } from '@mui/material';
import useLoading from 'modules/stores/loading/useLoading';
import { Paginator } from 'modules/theme/components/paginator/Paginator';
import { MAX_PAGES } from 'modules/advertisementsFilter/query/constants/pagination/pages/max/maxPages';
import { MAX_ADVERTISEMENTS } from 'modules/advertisementsFilter/query/constants/pagination/skip/max/maxSkip';
import { DEFAULT_LIMIT } from 'modules/advertisementsFilter/query/constants/pagination/limit/default/defaultLimit';
import { usePagination } from 'components/page/advertisement/listing/hooks/pagination/usePagination';
import { useWasInView } from 'modules/view/hooks/useWasInView';
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types';

interface IPagination {
    totalCount: number;
    isDisabled?: boolean;
}

export const Pagination: FC<IPagination> = ({
    totalCount,
    isDisabled,
}) => {
    const { start: startLoading } = useLoading();
    const { ref: wasInViewRef, wasInView } = useWasInView();
    const { prefetch } = useRouter();

    const {
        prevHref,
        nextHref,
        itemHref,
        currentPage,
    } = usePagination();

    const totalPageCount =
        totalCount >= MAX_ADVERTISEMENTS
            ? MAX_PAGES
            : Math.ceil(totalCount / DEFAULT_LIMIT);

    useEffect(() => {
        if (wasInView && nextHref) {
            prefetch(nextHref, { kind: PrefetchKind.FULL });
        }
    }, [wasInView, nextHref, prefetch]);

    return (
        <Box ref={wasInViewRef}>
            <Paginator
                showScrollToTopBtn
                prevHref={prevHref}
                nextHref={nextHref}
                itemHref={itemHref}
                onClick={startLoading}
                isDisabled={isDisabled}
                currentPage={currentPage}
                totalPageCount={totalPageCount}
            />
        </Box>
    );
};
