import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { IAdvertisementGtmListingItem } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementGtmListingItem';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { EItemListId } from 'modules/gtmEvents/enums/EItemListId';

export const mapIDevProjectToGtmListingItemMapper = (devProject: IDevProject): IAdvertisementGtmListingItem => {
    return {
        id: devProject.id,
        title: devProject.title,
        photoUrl: devProject.photoUrl,
        videos: [],
        location: {
            country: devProject.locationInfo.country,
            county: devProject.locationInfo.county,
            district: devProject.locationInfo.district,
            city: devProject.locationInfo.city,
        },
        advertiser: {
            id: '',
            name: devProject.advertiser.name,
            slugName: '',
            isPro: false,
            type: EAdvertiserType.AGENT,
            agency: {
                id: '',
                name: devProject.advertiser.agencyName,
                slugName: '',
            },
        },
        price: devProject.priceInfo,
        category: {
            mainValue: EMainCategory.APARTMENTS,
            subValue: ESubCategory.APARTMENT_HOUSE,
        },
        transaction: devProject.transaction,
        realEstateState: devProject.realEstateState,
        devProjectId: devProject.id,
        isTop: devProject.isTop,
        isPremium: devProject.isPremium,
        gaItemListName: EItemListId.EXCLUSIVE_LISTINGS,
    };
};
