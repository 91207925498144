import { IPackeges } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/partials/packages/IPackeges';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';

export const mapIAdvertisementsLisDevProjectToDsIDevProjectIPackages = ({
    isTop,
    isPremium,
}: IDevProject): IPackeges => {
    return {
        isTop,
        isPremium,
    };
};
