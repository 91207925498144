'use client';

import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ShowMoreBtn } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/partials/mobile/partials/showMoreBtn/ShowMoreBtn';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { Breadcrumb } from 'components/page/advertisement/listing/components/anotherOffer/partials/breadcrumbs/common/breadcrumb/Breadcrumb';
import { useWindowSize } from 'modules/window/useWindowSize';
interface IBreadcrumbsBox {
    breadcrumbs: IBreadcrumb[];
    maxHeight?: number;
}

export const BreadcrumbsBox: FC<IBreadcrumbsBox> = ({
    breadcrumbs,
    maxHeight = 250,
}) => {
    const contentRef = useRef(null);

    const [width] = useWindowSize();
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const [isOverflow, seIsOverflow] = useState<boolean>(false);

    useEffect(() => {
        const contentHeight = contentRef.current.scrollHeight;

        seIsOverflow(contentHeight > maxHeight);
        setIsShowMore(contentHeight < maxHeight);
    }, [maxHeight, contentRef, width]);

    return (
        <>
            <Box
                gap={1.5}
                width='100%'
                display='flex'
                flexWrap='wrap'
                ref={contentRef}
                alignItems='center'
                justifyContent='center'
                overflow={isOverflow && 'hidden'}
                maxHeight={(theme) => isOverflow && theme.spacing(45.75)}
                sx={{
                    ['-webkit-mask-image']:
                        !isShowMore &&
                        '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));',
                }}
            >
                {breadcrumbs.map((breadcrumb, index) => (
                    <Breadcrumb
                        key={index + breadcrumb.title}
                        breadcrumb={breadcrumb}
                    />
                ))}
            </Box>

            {isOverflow && (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='100%'
                >
                    <ShowMoreBtn
                        text={isShowMore ? 'Zobraziť menej' : 'Zobraziť všetky'}
                        onClick={() => setIsShowMore((current) => !current)}
                    />
                </Box>
            )}
        </>
    );
};
