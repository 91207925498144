import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { BreadcrumbsItem } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/breadcrumbs/partials/BreadcrumbsItem';
import KeyboardArrowRight from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IBreadcrumbs {
    items?: IBreadcrumb[];
    separator?: JSX.Element;
}

const SEPARATOR = (
    <Svg
        icon
        component={KeyboardArrowRight}
        width={16}
        height={16}
        color='labelTertiary'
    />
);

export const Breadcrumbs: FC<IBreadcrumbs> = ({
    items = [],
    separator = SEPARATOR,
}) => {
    const breadcrumbsItems = useMemo(() => {
        const itemNodes = [];

        if (!items.length) {
            itemNodes.push(<BreadcrumbsItem title='Vyberte si z ponuky' />);
        } else {
            items.forEach((item, index) => {
                const url =
                    (items.length - 1 !== index || items.length === 1) &&
                    item.url;

                itemNodes.push(
                    <BreadcrumbsItem title={item.title} url={url} />
                );
                if (items.length - 1 !== index) {
                    itemNodes.push(separator);
                }
            });
        }

        return itemNodes;
    }, [items, separator]);

    return (
        <Box
            gap={1}
            display='flex'
            paddingBottom={2}
            sx={{
                overflowX: 'auto',
            }}
        >
            {breadcrumbsItems.map((item, index) => (
                <Box
                    pt={2}
                    pb={1}
                    display='flex'
                    alignItems='center'
                    key={`breadcrumb-item-${index}`}
                    whiteSpace='nowrap'
                >
                    {item}
                </Box>
            ))}
        </Box>
    );
};
