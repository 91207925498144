import { AdvertisementListing } from 'modules/api/clients/advertisement/listing/AdvertisementsListing';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';

export const fetchExclusiveProjects = async (
    limit: number,
    skip: number
): Promise<IDevProject[]> => {
    try {
        return await new AdvertisementListing().getDevProjects(limit, skip);
    } catch (error) {
        throw new Error(
            `Failed to fetch listing dev projects. Error: ${error.message}`
        );
    }
};
