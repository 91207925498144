import { advertisementFavoriteApi } from 'modules/api/client';
import { AxiosResponse } from 'axios';

export const deleteFromFavorite = async (
    advertisementId: string,
): Promise<AxiosResponse<void>> => {
    try {
        const response = await advertisementFavoriteApi.deleteAdvertisementFavourite(
            advertisementId,
        );

        if (response.status !== 201) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        throw new Error(
            `Failed to delete from favorite. Error: ${error.message}`
        );
    }
};
