import { useCallback, useMemo } from 'react';
import { useSearchParams, usePathname } from 'next/navigation';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { MAX_PAGES } from 'modules/advertisementsFilter/query/constants/pagination/pages/max/maxPages';

interface IReturn {
    prevHref: string;
    nextHref: string;
    currentPage: number;
    itemHref: (page: number) => string;
}

export const usePagination = (): IReturn => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const currentPage = useMemo(() => {
        return parseInt(searchParams?.get('page') as string) || 1;
    }, [searchParams]);

    const getWindowUrl = useCallback(() => {
        const areParams = !!Array.from(searchParams).length;

        const currentHref = AppConfigService.getNehnutelnostiUrl() +
            `${pathname}${areParams ? '?' + searchParams.toString() : ''}`;

        const url = new URL(currentHref);

        const pageFromParams = searchParams.get('page');
        if (pageFromParams) {
            url.searchParams.delete('page');
        }

        return url;
    }, [pathname, searchParams]);

    const itemHref = useCallback((pageToSet: number) => {
        const url = getWindowUrl();

        if (pageToSet && pageToSet !== 1) {
            url.searchParams.append('page', pageToSet.toString());
        }

        return url.toString();
    }, [getWindowUrl]);

    const prevHref = useMemo(() => {
        const url = getWindowUrl();

        if (
            currentPage &&
            currentPage !== 1
        ) {
            url.searchParams.append('page', (currentPage - 1).toString());
        }

        if (
            currentPage &&
            currentPage === 2
        ) {
            url.searchParams.delete('page');
        }

        return url.toString();
    }, [getWindowUrl, currentPage]);

    const nextHref = useMemo(() => {
        const url = getWindowUrl();

        if (currentPage && currentPage !== MAX_PAGES) {
            url.searchParams.append('page', (currentPage + 1).toString());
        }

        return url.toString();
    }, [getWindowUrl, currentPage]);

    return {
        itemHref,
        prevHref,
        nextHref,
        currentPage,
    };
};
