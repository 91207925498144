import { useCallback, useEffect } from 'react';
import { getMultipleIsFavourite } from 'components/favorite/api/getMultipleIsFavourite';
import { addToFavorite } from 'components/favorite/api/addToFavorite';
import { useLocalStorageItem } from 'modules/localStorage/hooks/useLocalStorageitem';
import useSession from 'modules/stores/session/useSession';
import { useFavoriteStore } from 'components/favorite/store/favoriteStore';
import { isValueInEnum } from 'modules/enums/checker/isValueInEnum';
import {
    FavoriteErrorStatusCodeToMessagesRecord
} from 'components/favorite/constants/records/error/FavoriteErrorStatusCodeToMessagesRecord';
import { EFavoriteErrorStatusCode } from 'components/favorite/enums/error/messages/EFavoriteErrorStatusCode';
import useNotification from 'modules/notification/useNotification';

export const useCheckMultipleFavorite = (
    advertisementIds: string[]
): void => {
    const { session } = useSession();
    const isLoggedIn = !!session;

    const favoriteIds = useFavoriteStore((store) => store.favoriteIds);
    const setFavoriteIds = useFavoriteStore((store) => store.setFavoriteIds);
    const { error: showErrorNotification } = useNotification();

    const {
        get: getStorageAdvId,
        remove: removeStorageAdvId,
    } = useLocalStorageItem('add-to-favorites');

    const fetchMultipleIsFavourite = useCallback(async (): Promise<
        string[]
    > => {
        if (!isLoggedIn || !advertisementIds?.length) {
            return;
        }

        try {
            if (getStorageAdvId() !== null) {
                await addToFavorite(getStorageAdvId());
            }
        } catch (error) {
            if (isValueInEnum(error.message, EFavoriteErrorStatusCode)) {
                showErrorNotification(
                    FavoriteErrorStatusCodeToMessagesRecord[
                        error.message as EFavoriteErrorStatusCode
                    ]
                );
            }
            console.warn(error.message);
        } finally {
            setFavoriteIds([
                ...favoriteIds,
                getStorageAdvId(),
            ]);
            removeStorageAdvId();
        }

        try {
            const { data } = await getMultipleIsFavourite(advertisementIds);

            setFavoriteIds(data);
        } catch (error) {
            console.warn(error.message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, advertisementIds, getStorageAdvId, removeStorageAdvId, showErrorNotification]);

    useEffect(() => {
        fetchMultipleIsFavourite();
    }, [fetchMultipleIsFavourite]);
};
