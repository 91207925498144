import { create } from 'zustand';

type FavoriteStore = {
    isFavorite: boolean;
    favoriteIds: string[];
    setIsFavorite: (flag: boolean) => void;
    setFavoriteIds: (ids: string[]) => void;
};

export const useFavoriteStore = create<FavoriteStore>((set) => ({
    isFavorite: false,
    favoriteIds: [],
    setIsFavorite: (flag) => set(() => ({
        isFavorite: flag,
    })),
    setFavoriteIds: (ids) => set(() => ({
        favoriteIds: ids,
    })),
}));