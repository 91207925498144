import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

interface Props {
    html: string;
}

export const Content: FC<Props> = ({ html }) => {
    return (
        <Box
            sx={{
                '& p': {
                    m: 0,
                },
                '& strong': {
                    fontWeight: 600,
                },
            }}
        >
            <Text variant='label2' component='div'>
                {/* eslint-disable-next-line react/forbid-elements */}
                <div
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                />
            </Text>
        </Box>
    );
};
