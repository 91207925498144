'use client';

import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';

import HouseIcon from 'modules/theme/components/icons/category/House.svg';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import SearchIcon from 'modules/theme/components/icons/basic/SearchBold.svg';

export const IconsBox: FC = () => {
    return (
        <Stack flexDirection='row' columnGap={0.5}>
            <Svg
                icon
                width={24}
                height={24}
                color='primary'
                component={HouseIcon}
            />
            <Svg
                icon
                width={24}
                height={24}
                color='primary'
                component={MappinIcon}
            />
            <Svg
                icon
                width={24}
                height={24}
                color='primary'
                component={SearchIcon}
            />
        </Stack>
    );
};
