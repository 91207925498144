import { FC } from 'react';
import { Box } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { CtaBtn } from 'components/page/advertisement/listing/components/promoDevProjects/partials/ctaBtn/CtaBtn';
import { PromoDevProjectCarousel } from 'components/page/advertisement/listing/components/promoDevProjects/partials/promoDevProjectsCarousel/PromoDevProjectCarousel';
import { Title } from 'components/page/advertisement/listing/components/promoDevProjects/partials/title/Title';
import { SubTitle } from 'components/page/advertisement/listing/components/promoDevProjects/partials/subTitle/SubTitle';

interface IPromoDevProjects {
    devProjectsInitial: IDevProject[];
}
export const PromoDevProjects: FC<IPromoDevProjects> = ({
    devProjectsInitial,
}) => {
    return (
        <Box position='relative'>
            <Box
                mb={3}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
            >
                <Box mb={1}>
                    <Title />
                </Box>
                <Box
                    gap={1}
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Box>
                        <SubTitle />
                    </Box>
                    <CtaBtn
                        text='Zobraziť na mape'
                        url={`${AppConfigService.getNehnutelnostiUrl()}/developerske-projekty-a-novostavby/`}
                    />
                </Box>
            </Box>
            <PromoDevProjectCarousel
                devProjectsInitial={devProjectsInitial}
            />
        </Box>
    );
};
