import { IDevProject } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/IDevProject';
import { IPrice } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/partials/price/IPrice';
import { IDevProject as IApiDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { IPackeges } from 'modules/theme/components/listing/cards/promoDevProjectCard/interfaces/partials/packages/IPackeges';
import { ECategoryToDsCategory } from 'components/page/advertisement/listing/constants/records/promoDevProjectCard/category/ECategoryToDsCategory';
import { mapIAdvertisementsLisDevProjectToDsIDevProjectIPackages } from 'components/page/advertisement/listing/helpers/mapper/devProject/partials/packages/mapIAdvertisementsLisDevProjectToDsIDevProjectIPackages';
import { mapIAdvertisementsLisDevProjectPriceInfoToDsIDevProjectIPrice } from 'components/page/advertisement/listing/helpers/mapper/devProject/partials/price/mapIAdvertisementsLisDevProjectPriceInfoToDsIDevProjectIPrice';

export const mapIAdvertisementsLisDevProjectToDsIDevProject = (
    apiDevProject: IApiDevProject
): IDevProject => {
    const {
        id,
        title,
        sefName,
        photoUrl,
        priceInfo,
        advertisementsInfo,
        locationInfo: { locationString },
    } = apiDevProject;

    const totalAdvertisementsCount = advertisementsInfo.totalCount;
    const advertisementsCategories = advertisementsInfo.categories.map(
        (category) => ECategoryToDsCategory[category]
    );

    const price: IPrice =
        mapIAdvertisementsLisDevProjectPriceInfoToDsIDevProjectIPrice(
            priceInfo
        );

    const packages: IPackeges =
        mapIAdvertisementsLisDevProjectToDsIDevProjectIPackages(apiDevProject);

    return {
        price,
        packages,
        projectId: id,
        projectName: title,
        imageUrl: photoUrl,
        totalAdvertisementsCount,
        advertisementsCategories,
        projectSlugName: sefName,
        location: locationString,
    };
};
